import React from "react";
import logo from "./logo.svg";
import "./App.css";
import Configurator from "./configurator/configurator";
import ConfigurationLoader from "./configurationLoader";
import { BrowserRouter as Router, Route, Link, Routes } from "react-router-dom";
import Lobby from "./lobby";
import { Layout, Menu, Breadcrumb, Select, Space } from "antd";
import Home from "./home";
import { RaceConfigurator } from "./raceConfigurator";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { Constants } from "./utils/Constants";
import ClientExplorer from "./clientExplorer";
import TesterGame from "./testerGame";
import TesterGameDev from "./testerGameDev";

const { Header, Content, Footer } = Layout;

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [jwt, setJWT] = useState<string>();
  const [userInfo, setUserInfo] = useState<any>();
  const [loadingProfile, setLoadingProfile] = useState<boolean>(false);

  useEffect(() => {
    const fetchMe = async (token: string) => {
      setLoadingProfile(true);
      try {
        const user = await axios.get(Constants.ELANTRA_AUTH_ME, {
          headers: { authorization: `Bearer ${token}` },
        });
        if (user) {
          console.log(user);
          setIsLoggedIn(true);
          setUserInfo(user.data);
        } else {
        }
      } catch (ex) {}
      setLoadingProfile(false);
    };

    const jwtLs = localStorage.getItem("jwt"); // useLocaltorage state
    if (jwtLs && jwtLs.length > 0) fetchMe(jwtLs);
  }, [jwt]);
  const tenants: any = [];
  userInfo?.tenants?.map((tenant: any) => {
    const singleTenant = {
      value: tenant.tenant.name,
      label: tenant.tenant.name,
    };
    tenants.push(singleTenant);
  });
  if (isLoggedIn)
    return (
      <Router>
        <Layout className="layout">
          <Header style={{ height: 78, display: "flex", alignItems: "center" }}>
            <div style={{ width: "100%" }}>
              <div className="logo" />
              <Menu theme="dark" mode="horizontal" defaultSelectedKeys={["2"]}>
                <Space
                  direction="horizontal"
                  style={{ justifyContent: "space-between", width: "100%" }}
                >
                  <div style={{ display: "flex", gap: 24 }}>
                    <div>
                      <Link style={{ color: "#b9b9b9", fontSize: 16 }} to="/">
                        Home
                      </Link>
                    </div>
                    <div>
                      <Link
                        style={{ color: "#b9b9b9", fontSize: 16 }}
                        to="/configurator"
                      >
                        Configurator
                      </Link>
                    </div>
                    <div>
                      <Link
                        style={{ color: "#b9b9b9", fontSize: 16 }}
                        to="/race-configurator"
                      >
                        Race Configurator
                      </Link>
                    </div>
                    <div>
                      <Link
                        style={{ color: "#b9b9b9", fontSize: 16 }}
                        to="/lobby"
                      >
                        Lobby
                      </Link>
                    </div>
                  </div>
                  <div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <Link
                        to="#"
                        style={{
                          color: "red",
                          lineHeight: "32px",
                          fontSize: 16,
                          fontWeight: 500,
                        }}
                      >
                        {userInfo?.email}
                      </Link>
                      <Select defaultValue="Select" options={tenants} />
                    </div>
                  </div>
                </Space>
              </Menu>
            </div>
          </Header>
          <Content
            style={{
              padding: "0 50px",
              background: "#fff",
              minHeight: "500px",
            }}
          >
            <Routes>
              <Route
                path="/configurator"
                element={
                  <div>
                    <ConfigurationLoader></ConfigurationLoader>
                    <Configurator></Configurator>
                  </div>
                }
              ></Route>
              <Route
                path="/race-configurator"
                element={<RaceConfigurator />}
              ></Route>
              <Route path="/lobby" element={<Lobby></Lobby>}></Route>
              <Route
                path="/client"
                element={<ClientExplorer></ClientExplorer>}
              ></Route>
              <Route
                path="/"
                element={
                  <Home
                    onLoginSuccess={(tkn: string) => {
                      setJWT(tkn);
                    }}
                    isLoggedIn={isLoggedIn}
                  ></Home>
                }
              ></Route>
              <Route path="/tester-game" element={<TesterGame />}></Route>
              <Route path="/tester-game-dev" element={<TesterGameDev />}></Route>
            </Routes>
          </Content>
          <Footer style={{ textAlign: "center" }}>
            All Rights Reserved @ Lucky Beetle Games
          </Footer>
        </Layout>
      </Router>
    );
  return (
    <Router>
      <Routes>
        <Route path="/tester-game" element={<TesterGame />}></Route>
        <Route path="/tester-game-dev" element={<TesterGameDev />}></Route>
        <Route
          path="*"
          element={
            <Home
              onLoginSuccess={(tkn: string) => {
                setJWT(tkn);
              }}
              loadingProfile={loadingProfile}
              isLoggedIn={isLoggedIn}
            ></Home>
          }
        ></Route>
      </Routes>
      
    </Router>
  );
}

export default App;
